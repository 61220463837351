import React from 'react';
import { graphql } from 'gatsby';

import Seo from '../../components/seo';

import PageScreen from '../../screens/page';
import usePreviewData from '../../utils/usePreviewData';

import { stripHtml, Product, oneMatch } from '../../utils';
import { trackProductList } from '../../utils/gtm';
import { transformArticle } from '../../utils/Article';

const ArticleTemplate = ({ data: rawData, pageContext }) => {
  const data = usePreviewData(rawData);
  const article = data?.prismicArticles;

  console.log(rawData);
  console.log(pageContext);

  const moreArticles = data?.more?.nodes || [];

  if (article?.data && !article?.data?.cover?.url) {
    article.data.cover = data?.prismicHomearticle?.data?.cover;
  }

  const displayedProducts = [];
  const products = (data?.products?.nodes || []).map((p) => Product(p));
  const content = (article?.data?.body || []).map((slice) => {
    if (slice.slice_type === 'carousel') {
      return {
        ...slice,
        items: (slice.items || []).map((i) => i.image).filter((i) => i),
      };
    }

    if (slice.slice_type === 'produits') {
      return {
        ...slice,
        items: (slice.items || [])
          .map((i) => {
            const current = products.find((p) =>
              oneMatch(
                p.skus,
                i.product?.variants?.map((v) => v.sku)
              )
            );
            if (!current) {
              return null;
            }
            const result = {
              ...current,
              trackList: 'Article',
              trackPosition: displayedProducts.length + 1,
            };

            displayedProducts.push(result);
            return result;
          })
          .filter((p) => p),
      };
    }

    return slice;
  });

  trackProductList(
    displayedProducts.map((p) => ({
      sku: p.defaultVariant?.sku,
      name: p.title,
      price: p.defaultVariant?.price,
      variant: p.defaultVariant?.title,
      category: p.productType,
      list: p.trackList,
      position: p.trackPosition,
    }))
  );

  const firstText = (article?.data?.body || []).find(
    (s) => s.slice_type === 'contenu'
  );
  const firstTextHtml = firstText?.primary?.content?.html;

  console.log('***', article);
  return (
    <>
      <Seo
        title={article.data?.title}
        description={stripHtml(firstTextHtml || '')}
        image={article.data?.thumbnail?.url}
      />
      <PageScreen
        page={{
          ...article?.data,
          uid: article?.uid,
          body: content,
        }}
        articles={moreArticles?.map(transformArticle)}
        showBreadcrumb
      />
    </>
  );
};

export const query = graphql`
  query ArticlesItem(
    $prismic: ID!
    $locale: String!
    # $cmsLocale: String!
    $cmsLocaleGlob: String!
    $fetchProducts: [String!]
  ) {
    prismicHomearticle(lang: { glob: $cmsLocaleGlob }) {
      data {
        cover {
          url
          alt
          dimensions {
            width
            height
          }
        }
      }
    }
    prismicArticles(prismicId: { eq: $prismic }) {
      data {
        date
        title
        body {
          ... on PrismicArticlesDataBodyProduits {
            id
            primary {
              section_title
            }
            items {
              product {
                variants {
                  sku
                }
              }
            }
            slice_type
          }
          ... on PrismicArticlesDataBodyCarousel {
            id
            primary {
              display_grid
              section_title
            }
            slice_type
            slice_label
            items {
              image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicArticlesDataBodyContenu {
            id
            slice_type
            slice_label
            primary {
              content {
                html
                richText: raw
              }
            }
          }
          ... on PrismicArticlesDataBodyFaq {
            id
            slice_type
            items {
              section_title
              content {
                html
              }
            }
          }

          ... on PrismicArticlesDataBodyIframe {
            id
            slice_type
            primary {
              url {
                url
              }
            }
          }
        }
        cover {
          alt
          url
          dimensions {
            height
            width
          }
        }
        thumbnail {
          url
        }
      }
      date: first_publication_date
      uid
    }
    more: allPrismicArticles(
      limit: 3
      filter: { lang: { glob: $cmsLocaleGlob }, prismicId: { nin: [$prismic] } }
    ) {
      nodes {
        date: first_publication_date
        url
        data {
          title
          thumbnail {
            alt
            url
            dimensions {
              height
              width
            }
          }
          cover {
            alt
            url
            dimensions {
              height
              width
            }
          }
        }
      }
    }
    products: allShopifyProduct(
      filter: { skus: { in: $fetchProducts }, locale: { eq: $locale } }
    ) {
      nodes {
        shopifyAdminId
        ...ProductItem
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        options {
          name
          values
        }
      }
    }
  }
`;
export default ArticleTemplate;
